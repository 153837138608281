import { INITIAL_PAGE } from '../const/initial-pages.const';
import { CurrentUser } from '../models/current-user.model';

export function navigateToUserPages(currentUser: CurrentUser): string {
  if (currentUser.isOperator()) {
    return INITIAL_PAGE.operator;
  }

  if (currentUser.isProvider()) {
    return INITIAL_PAGE.doer;
  }

  return INITIAL_PAGE.user;
}
