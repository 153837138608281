import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'visitStatus'
})
export class VisitStatusPipe implements PipeTransform {
  status = {
    pending: 'Pendiente',
    finished: 'Finalizada'
  };

  transform(value: string): string {
    return this.status[value];
  }
}
