export const SERVICE_STATUSSES = {
  authorization_pending: 'Pdte. de autorizar',
  provider_assignment_pending: 'Pdte. de asignar técnico',
  user_contact_pending: 'Pdte. 1er contacto cliente',
  visit_pending: 'Pdte. de visitar',
  budget_pending: 'Pdte. de presupuesto',
  budget_review_pending: 'Pdte. de revisar presupuesto',
  acceptance_budget_pending: 'Pdte. aceptación de presupuesto',
  finalizing_pending: 'Pdte. de finalizar',
  review_pending: 'Pdt de revisión',
  documentation_pending: 'Pdte. de documentación',
  rejected: 'Rechazado',
  completed: 'Finalizado',
  on_hold: 'Congelado'
};
